"use strict";

var _interopRequireDefault = require("E:/project/taiqiu/back-end/base/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getInfo = getInfo;
var _request = _interopRequireDefault(require("@/utils/request"));
function getInfo() {
  var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return (0, _request.default)({
    url: '/core/config/info',
    method: 'get',
    params: params
  });
}