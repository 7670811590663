"use strict";

var _interopRequireDefault = require("E:/project/taiqiu/back-end/base/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _axios = _interopRequireDefault(require("axios"));
var _elementUi = require("element-ui");
var _store = _interopRequireDefault(require("@/store"));
var _auth = require("@/utils/auth");
var _setting = _interopRequireDefault(require("@/setting.js"));
var _qs = _interopRequireDefault(require("qs"));
// create an axios instance
var service = _axios.default.create({
  baseURL: process.env.VUE_APP_BASE_API,
  // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 30000 // request timeout
});

// 请求拦截器
service.interceptors.request.use(function (config) {
  // token
  config.headers["app-id"] = _setting.default.appid;
  if (_store.default.getters.token && _store.default.getters.token !== undefined) {
    config.headers["user-token"] = (0, _auth.getToken)();
    config.headers["client-type"] = "web";
  }
  // signature
  if (config.method === "post") {
    config.data = (0, _auth.getSignature)(config.data);
    // 当type为form-data时，即表示是文件上传
    if (config.type !== "form-data") {
      config.data = _qs.default.stringify(config.data);
    }
  } else if (config.method === "get") {
    config.params = (0, _auth.getSignature)(config.params);
  }
  return config;
}, function (error) {
  // do something with request error
  console.log("requet Error", error); // for debug
  return Promise.reject(error);
});

// 响应拦截器
service.interceptors.response.use(
/**
 * If you want to get http information such as headers or status
 * Please return  response => response
 */
function (response) {
  var res = response.data;
  // console.log(response.config.url, res);
  if (res.code === -1) {
    // 登录态过期，重新登录
    _store.default.dispatch("user/resetToken").then(function () {
      location.reload();
    });
  }
  return res;
}, function (error) {
  error = error.response;
  (0, _elementUi.Message)({
    type: "error",
    message: error.statusText + "：" + error.status,
    showClose: true,
    duration: 3 * 1000
  });
  return Promise.reject(error.data);
});
var _default = service;
exports.default = _default;