"use strict";

var _interopRequireDefault = require("E:/project/taiqiu/back-end/base/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es7.object.get-own-property-descriptors");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.object.keys");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("E:/project/taiqiu/back-end/base/node_modules/@babel/runtime/helpers/defineProperty.js"));
var _vuex = require("vuex");
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
var _default = {
  name: 'Msg',
  data: function data() {
    return {
      visible: false
    };
  },
  computed: _objectSpread({}, (0, _vuex.mapGetters)(['msg'])),
  methods: {
    toggle: function toggle() {
      this.visible = !this.visible;
    },
    hidden: function hidden() {
      this.visible = false;
    },
    show: function show() {
      this.visible = true;
    }
  }
};
exports.default = _default;