"use strict";

var _interopRequireDefault = require("E:/project/taiqiu/back-end/base/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getCode = getCode;
exports.getImage = getImage;
var _request = _interopRequireDefault(require("@/utils/request"));
function getImage(data) {
  return (0, _request.default)({
    url: '/core/captcha/image',
    method: 'post',
    data: data
  });
}
function getCode(data) {
  return (0, _request.default)({
    url: '/core/captcha/mobile',
    method: 'post',
    data: data
  });
}