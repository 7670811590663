var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "login-container",
      style: { backgroundImage: _vm.backgroundImage },
    },
    [
      !_vm.backgroundImage
        ? _c(
            "div",
            { staticClass: "bubbles" },
            _vm._l(_vm.backgroundBubbles, function (item, index) {
              return _c(
                "svg",
                {
                  key: index,
                  attrs: {
                    id: "StartCMS",
                    version: "1.1",
                    xmlns: "http://www.w3.org/2000/svg",
                    "xmlns:xlink": "http://www.w3.org/1999/xlink",
                    x: "0px",
                    y: "0px",
                    width: "128px",
                    height: "128px",
                    viewBox: "0 0 128 128",
                    "enable-background": "new 0 0 128 128",
                    "xml:space": "preserve",
                  },
                },
                [
                  _c("path", {
                    attrs: {
                      fill: "#71C0E9",
                      "fill-opacity": "0.35",
                      d: "M97.658,48.586c0.109,0.193,0.169,0.412,0.169,0.635v33.655c0,0.915-0.489,1.761-1.284,2.216l-29.363,16.84\n\tc-0.611,0.352-1.393,0.139-1.744-0.473c-0.109-0.193-0.168-0.412-0.168-0.636V67.169c0-0.915,0.489-1.761,1.283-2.216l29.363-16.84\n\tC96.525,47.763,97.307,47.974,97.658,48.586L97.658,48.586z",
                    },
                  }),
                  _c("path", {
                    attrs: {
                      fill: "#71C0E9",
                      "fill-opacity": "0.35",
                      d: "M30.932,47.944c0.223,0,0.442,0.058,0.635,0.169l29.364,16.84c0.794,0.455,1.283,1.301,1.283,2.216v33.654\n\tc0,0.706-0.571,1.276-1.277,1.276c-0.223,0-0.442-0.059-0.635-0.168L30.939,85.091c-0.794-0.455-1.284-1.301-1.284-2.216V49.221\n\tC29.655,48.516,30.227,47.944,30.932,47.944L30.932,47.944z",
                    },
                  }),
                  _c("path", {
                    attrs: {
                      fill: "#71C0E9",
                      "fill-opacity": "0.35",
                      d: "M65.646,26.805l28.717,16.469c0.611,0.351,0.822,1.131,0.473,1.743c-0.113,0.197-0.275,0.36-0.473,0.472\n\tL65.646,61.958c-1.182,0.677-2.631,0.677-3.813,0L33.119,45.489c-0.612-0.351-0.823-1.131-0.472-1.743\n\tc0.113-0.197,0.276-0.36,0.472-0.472l28.717-16.468C63.016,26.128,64.466,26.128,65.646,26.805L65.646,26.805z",
                    },
                  }),
                  _c("path", {
                    attrs: {
                      fill: "#BFBFBF",
                      "fill-opacity": "0.35",
                      d: "M63.866,7.875c2.973,0,5.407,2.3,5.618,5.216l35.623,20.543c0.838-0.468,1.783-0.713,2.742-0.711\n\tc3.111,0,5.633,2.519,5.633,5.625c0,2.206-1.271,4.115-3.121,5.037v40.569c1.994,0.865,3.39,2.851,3.39,5.16\n\tc0,3.107-2.521,5.627-5.633,5.627c-1.334,0.002-2.627-0.473-3.644-1.338L69.455,113.8c0.027,0.229,0.043,0.464,0.043,0.699\n\tc0,3.106-2.521,5.626-5.632,5.626s-5.632-2.52-5.632-5.626c0-0.263,0.018-0.522,0.053-0.776L23.833,93.855\n\tc-1.003,0.924-2.345,1.487-3.817,1.487c-3.11,0-5.632-2.518-5.632-5.625c0-2.209,1.274-4.12,3.129-5.041v-41.29\n\tc-1.927-0.894-3.263-2.844-3.263-5.105c0-3.107,2.521-5.626,5.632-5.626c1.12,0,2.163,0.327,3.041,0.89l35.32-20.369\n\tC58.411,10.221,60.864,7.875,63.866,7.875z M68.823,16.174c-0.952,1.758-2.815,2.953-4.958,2.953c-2.116,0-3.96-1.167-4.922-2.891\n\tl-33.988,19.6c0.369,0.763,0.56,1.599,0.559,2.446c0,2.894-2.188,5.277-5.001,5.591v40.242c2.878,0.251,5.135,2.664,5.135,5.604\n\tc0,0.551-0.08,1.084-0.228,1.588l34.071,19.648c1.033-1.27,2.608-2.082,4.374-2.082c1.792,0,3.388,0.836,4.419,2.139l34.495-19.893\n\tc-0.195-0.581-0.295-1.189-0.295-1.803c0-2.852,2.123-5.207,4.875-5.576V44.155c-2.881-0.248-5.144-2.663-5.144-5.605\n\tc-0.001-0.95,0.239-1.884,0.698-2.716L68.823,16.174L68.823,16.174z",
                    },
                  }),
                ]
              )
            }),
            0
          )
        : _vm._e(),
      _c(
        "el-form",
        {
          ref: "loginForm",
          staticClass: "login-form shadow",
          attrs: {
            rules: _vm.loginRules,
            model: _vm.loginForm,
            autocomplete: "on",
            "label-position": "left",
            size: "medium",
          },
        },
        [
          _vm.core.title
            ? _c("h3", { staticClass: "title" }, [
                _vm._v(_vm._s(_vm.core.title)),
              ])
            : _c("h3", {
                staticClass: "title",
                staticStyle: { height: "30px" },
              }),
          _c(
            "el-tabs",
            {
              model: {
                value: _vm.formType,
                callback: function ($$v) {
                  _vm.formType = $$v
                },
                expression: "formType",
              },
            },
            [
              _c(
                "el-tab-pane",
                { attrs: { label: "密码登录", name: "login" } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { prop: "account" } },
                    [
                      _c(
                        "span",
                        { staticClass: "svg-container" },
                        [_c("svg-icon", { attrs: { "icon-class": "user" } })],
                        1
                      ),
                      _c("el-input", {
                        ref: "account",
                        attrs: {
                          placeholder: "账户名/手机号",
                          name: "account",
                          type: "text",
                          tabindex: "1",
                          autocomplete: "on",
                        },
                        model: {
                          value: _vm.loginForm.account,
                          callback: function ($$v) {
                            _vm.$set(_vm.loginForm, "account", $$v)
                          },
                          expression: "loginForm.account",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "password" } },
                    [
                      _c(
                        "span",
                        { staticClass: "svg-container" },
                        [
                          _c("svg-icon", {
                            attrs: { "icon-class": "password" },
                          }),
                        ],
                        1
                      ),
                      _c("el-input", {
                        key: _vm.passwordType,
                        ref: "password",
                        attrs: {
                          type: _vm.passwordType,
                          placeholder: "登录密码",
                          name: "password",
                          tabindex: "2",
                          autocomplete: "on",
                        },
                        nativeOn: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.handleLogin($event)
                          },
                        },
                        model: {
                          value: _vm.loginForm.password,
                          callback: function ($$v) {
                            _vm.$set(_vm.loginForm, "password", $$v)
                          },
                          expression: "loginForm.password",
                        },
                      }),
                      _c(
                        "span",
                        { staticClass: "show-pwd", on: { click: _vm.showPwd } },
                        [
                          _c("svg-icon", {
                            attrs: {
                              "icon-class":
                                _vm.passwordType === "password"
                                  ? "eye"
                                  : "eye-open",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "code" } },
                    [
                      _c(
                        "span",
                        { staticClass: "svg-container" },
                        [_c("svg-icon", { attrs: { "icon-class": "lock" } })],
                        1
                      ),
                      _c("el-input", {
                        ref: "captcha",
                        attrs: {
                          placeholder: "验证码",
                          name: "code",
                          tabindex: "3",
                          autocomplete: "off",
                        },
                        nativeOn: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.handleLogin($event)
                          },
                        },
                        model: {
                          value: _vm.loginForm.code,
                          callback: function ($$v) {
                            _vm.$set(_vm.loginForm, "code", $$v)
                          },
                          expression: "loginForm.code",
                        },
                      }),
                      _c(
                        "span",
                        {
                          staticClass: "change-code",
                          on: { click: _vm.handleImage },
                        },
                        [
                          _c("input", {
                            attrs: { type: "hidden", name: "uniqid" },
                            domProps: { value: _vm.captcha.uniqid },
                          }),
                          _c("img", { attrs: { src: _vm.captcha.image } }),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-button",
                    {
                      staticStyle: { width: "100%", "margin-top": "30px" },
                      attrs: {
                        loading: _vm.btnLoading,
                        type: "primary",
                        size: "medium",
                      },
                      nativeOn: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.handleLogin($event)
                        },
                      },
                    },
                    [_vm._v("登录")]
                  ),
                ],
                1
              ),
              _c(
                "el-tab-pane",
                {
                  attrs: {
                    name: "register",
                    label: "手机登陆",
                    disabled: !_vm.core.open_register,
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { prop: "mobile" } },
                    [
                      _c("span", { staticClass: "svg-container" }, [
                        _c("i", { staticClass: "el-icon-mobile-phone" }),
                      ]),
                      _c("el-input", {
                        ref: "mobile",
                        attrs: {
                          placeholder: "手机号码",
                          name: "mobile",
                          type: "text",
                          size: "medium",
                          tabindex: "1",
                          autocomplete: "on",
                        },
                        model: {
                          value: _vm.loginForm.mobile,
                          callback: function ($$v) {
                            _vm.$set(_vm.loginForm, "mobile", $$v)
                          },
                          expression: "loginForm.mobile",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "code" } },
                    [
                      _c("span", { staticClass: "svg-container" }, [
                        _c("i", { staticClass: "el-icon-message" }),
                      ]),
                      _c("el-input", {
                        ref: "captcha",
                        attrs: {
                          placeholder: "验证码",
                          name: "code",
                          size: "medium",
                          tabindex: "2",
                          autocomplete: "off",
                        },
                        nativeOn: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.handleLogin($event)
                          },
                        },
                        model: {
                          value: _vm.loginForm.code,
                          callback: function ($$v) {
                            _vm.$set(_vm.loginForm, "code", $$v)
                          },
                          expression: "loginForm.code",
                        },
                      }),
                      _c(
                        "span",
                        {
                          staticClass: "change-code",
                          on: { click: _vm.handleCode },
                        },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: {
                                disabled: _vm.btnDisabled,
                                type: "text",
                              },
                            },
                            [_vm._v(_vm._s(_vm.btnText))]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "tips text-right text-df" }, [
                    _c("span", [_vm._v("未注册手机号验证后自动注册")]),
                  ]),
                  _c("div", {
                    staticClass:
                      "social-login flex align-center justify-center",
                  }),
                  _c(
                    "el-button",
                    {
                      staticStyle: { width: "100%", "margin-top": "30px" },
                      attrs: {
                        loading: _vm.btnLoading,
                        type: "primary",
                        size: "medium",
                      },
                      nativeOn: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.handleRegister($event)
                        },
                      },
                    },
                    [_vm._v("注册/登录")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._m(0),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "copyright" }, [
      _vm._v("Powered by "),
      _c("a", { attrs: { href: "http://www.startcms.cn", target: "_blank" } }, [
        _vm._v("start-cms"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }