"use strict";

var _interopRequireDefault = require("E:/project/taiqiu/back-end/base/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es7.object.get-own-property-descriptors");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.object.keys");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("E:/project/taiqiu/back-end/base/node_modules/@babel/runtime/helpers/defineProperty.js"));
var _vuex = require("vuex");
var _Logo = _interopRequireDefault(require("./Logo"));
var _Menu = _interopRequireDefault(require("./Menu"));
var _Profile = _interopRequireDefault(require("../Profile"));
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
var _default = {
  components: {
    Logo: _Logo.default,
    AppMenu: _Menu.default,
    Profile: _Profile.default
  },
  data: function data() {
    return {
      list: []
    };
  },
  computed: _objectSpread(_objectSpread({}, (0, _vuex.mapGetters)(['config', 'sidebar', 'appNavbar'])), {}, {
    layout: function layout() {
      return this.config.layout;
    },
    textColor: function textColor() {
      return this.config.text_color;
    },
    backgroundColor: function backgroundColor() {
      return this.config.background_color;
    },
    isCollapse: function isCollapse() {
      return !this.sidebar.opened;
    }
  }),
  methods: {}
};
exports.default = _default;