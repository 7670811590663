var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "profile-container" },
    [
      _c(
        "el-dropdown",
        {
          staticClass: "profile-item hover-effect",
          style: "color:" + _vm.textColor + ";",
          attrs: { trigger: "click" },
        },
        [
          _c("div", { staticClass: "avatar-wrapper" }, [
            _vm.user.avatar
              ? _c("img", {
                  staticClass: "user-avatar",
                  attrs: { src: _vm.user.avatar + "?imageView2/1/w/80/h/80" },
                })
              : _vm._e(),
            _c("span", [_vm._v(_vm._s(_vm.user.name))]),
            _c("i", { staticClass: "el-icon-caret-bottom" }),
          ]),
          _c(
            "el-dropdown-menu",
            { attrs: { slot: "dropdown" }, slot: "dropdown" },
            [
              _c(
                "router-link",
                { attrs: { to: "/core/user/current" } },
                [_c("el-dropdown-item", [_vm._v("个人设置")])],
                1
              ),
              _c(
                "el-dropdown-item",
                { staticStyle: { display: "block" }, attrs: { divided: "" } },
                [
                  _c("span", { on: { click: _vm.handleLogout } }, [
                    _vm._v("退出登录"),
                  ]),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }