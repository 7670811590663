"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.object.keys");
var _config = require("@/api/config");
var _captcha = require("@/api/captcha");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'Login',
  components: {},
  data: function data() {
    var _this = this;
    var validateMobile = function validateMobile(rule, value, callback) {
      if (_this.formType === 'register' && !value) {
        callback(new Error('手机号不能为空'));
      } else {
        callback();
      }
    };
    var validateAccount = function validateAccount(rule, value, callback) {
      if (_this.formType === 'login' && !value) {
        callback(new Error('账户不能为空'));
      } else {
        callback();
      }
    };
    var validatePassword = function validatePassword(rule, value, callback) {
      if (_this.formType === 'login' && !value) {
        callback(new Error('密码不能为空'));
      } else {
        callback();
      }
    };
    var validateCode = function validateCode(rule, value, callback) {
      if (!value) {
        callback(new Error('验证码不能为空'));
      } else {
        callback();
      }
    };
    return {
      core: {
        logo: '',
        title: '',
        open_register: false
      },
      formType: 'login',
      loginForm: {
        mobile: '',
        account: '',
        password: '',
        code: ''
      },
      loginRules: {
        mobile: [{
          required: true,
          trigger: 'blur',
          validator: validateMobile
        }],
        account: [{
          required: true,
          trigger: 'blur',
          validator: validateAccount
        }],
        password: [{
          required: true,
          trigger: 'blur',
          validator: validatePassword
        }],
        code: [{
          required: true,
          trigger: 'blur',
          validator: validateCode
        }]
      },
      captcha: {
        image: '',
        uniqid: ''
      },
      btnText: '获取短信验证码',
      btnLoading: false,
      btnDisabled: false,
      backgroundImage: '',
      backgroundBubbles: new Array(10),
      passwordType: 'password',
      redirect: undefined,
      otherQuery: {}
    };
  },
  computed: {},
  watch: {
    $route: {
      handler: function handler(route) {
        var query = route.query;
        if (query) {
          this.redirect = query.redirect;
          this.otherQuery = this.getOtherQuery(query);
        }
      },
      immediate: true
    }
  },
  created: function created() {
    var _this2 = this;
    (0, _config.getInfo)({
      app: 'core'
    }).then(function (response) {
      _this2.core = response.data;
      if (_this2.core.background_image) {
        _this2.backgroundImage = 'url(' + _this2.core.background_image + ')';
      }
    });
    this.handleImage();
  },
  mounted: function mounted() {
    if (this.loginForm.account === '') {
      this.$refs.account.focus();
    } else if (this.loginForm.password === '') {
      this.$refs.password.focus();
    }
  },
  methods: {
    showPwd: function showPwd() {
      var _this3 = this;
      if (this.passwordType === 'password') {
        this.passwordType = '';
      } else {
        this.passwordType = 'password';
      }
      this.$nextTick(function () {
        _this3.$refs.password.focus();
      });
    },
    handleImage: function handleImage() {
      var _this4 = this;
      (0, _captcha.getImage)().then(function (res) {
        _this4.captcha = res.data;
        _this4.loginForm.code = '';
      });
    },
    handleCode: function handleCode() {
      var _this5 = this;
      if (this.btnDisabled) {
        return false;
      }
      var mobile = this.loginForm.mobile;
      this.btnDisabled = true;
      (0, _captcha.getCode)({
        mobile: mobile
      }).then(function (res) {
        if (!res.code) {
          var time = 60;
          var inter = setInterval(function () {
            _this5.btnText = '重新获取(' + time + ')';
            time--;
            if (time === 0) {
              _this5.btnText = '获取短信验证码';
              _this5.btnDisabled = false;
              clearInterval(inter);
            }
          }, 1000);
        }
      }).catch(function () {
        _this5.btnDisabled = false;
      });
    },
    handleLogin: function handleLogin() {
      var _this6 = this;
      this.$refs.loginForm.validate(function (valid) {
        if (valid) {
          Object.assign(_this6.loginForm, _this6.captcha);
          _this6.btnLoading = true;
          _this6.$store.dispatch('user/login', _this6.loginForm).then(function (res) {
            _this6.btnLoading = false;
            if (res.code) {
              _this6.$message.error(res.msg);
              _this6.handleImage();
              return;
            }
            _this6.$router.push({
              path: _this6.redirect || '/',
              query: _this6.otherQuery
            });
          }).catch(function () {
            _this6.btnLoading = false;
            _this6.handleImage();
          });
        } else {
          return false;
        }
      });
    },
    handleRegister: function handleRegister() {
      var _this7 = this;
      this.$refs.loginForm.validate(function (valid) {
        if (valid) {
          _this7.btnLoading = true;
          _this7.$store.dispatch('user/register', _this7.loginForm).then(function (res) {
            _this7.btnLoading = false;
            if (res.code) {
              _this7.$message.error(res.msg);
              return;
            }
            _this7.$router.push({
              path: _this7.redirect || '/',
              query: _this7.otherQuery
            });
          }).catch(function () {
            _this7.btnLoading = false;
          });
        } else {
          return false;
        }
      });
    },
    getOtherQuery: function getOtherQuery(query) {
      return Object.keys(query).reduce(function (acc, cur) {
        if (cur !== 'redirect') {
          acc[cur] = query[cur];
        }
        return acc;
      }, {});
    }
  }
};
exports.default = _default;