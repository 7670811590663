"use strict";

var _interopRequireDefault = require("E:/project/taiqiu/back-end/base/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
var _typeof3 = require("E:/project/taiqiu/back-end/base/node_modules/@babel/runtime/helpers/typeof.js");
require("core-js/modules/es6.string.iterator");
require("core-js/modules/es6.weak-map");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.array.sort");
require("core-js/modules/es6.function.name");
require("core-js/modules/web.dom.iterable");
var _typeof2 = _interopRequireDefault(require("E:/project/taiqiu/back-end/base/node_modules/@babel/runtime/helpers/typeof.js"));
var _jsCookie = _interopRequireDefault(require("js-cookie"));
var _tree = _interopRequireDefault(require("@/utils/tree"));
var _router = require("@/router");
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof3(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
var state = {
  msg: [],
  apps: [],
  appNavbar: [],
  appRoutes: [],
  sidebar: {
    opened: _jsCookie.default.get("sidebarStatus") ? !!+_jsCookie.default.get("sidebarStatus") : true,
    withoutAnimation: false
  },
  device: "desktop",
  size: _jsCookie.default.get("size") || "medium"
};
var mutations = {
  TOGGLE_SIDEBAR: function TOGGLE_SIDEBAR(state) {
    state.sidebar.opened = !state.sidebar.opened;
    state.sidebar.withoutAnimation = false;
    if (state.sidebar.opened) {
      _jsCookie.default.set("sidebarStatus", 1);
    } else {
      _jsCookie.default.set("sidebarStatus", 0);
    }
  },
  CLOSE_SIDEBAR: function CLOSE_SIDEBAR(state, withoutAnimation) {
    _jsCookie.default.set("sidebarStatus", 0);
    state.sidebar.opened = false;
    state.sidebar.withoutAnimation = withoutAnimation;
  },
  TOGGLE_DEVICE: function TOGGLE_DEVICE(state, device) {
    state.device = device;
  },
  SET_SIZE: function SET_SIZE(state, size) {
    state.size = size;
    _jsCookie.default.set("size", size);
  },
  SET_APPS: function SET_APPS(state, apps) {
    state.apps = apps;
  },
  SEND_MSG: function SEND_MSG(state, msg) {
    state.msg.push(msg);
  },
  SET_NAVBAR: function SET_NAVBAR(state, menus) {
    state.appNavbar = menus;
  },
  SET_ROUTES: function SET_ROUTES(state, routes) {
    state.appRoutes = _router.constantRoutes.concat(routes);
  }
};
var actions = {
  toggleSideBar: function toggleSideBar(_ref) {
    var commit = _ref.commit;
    commit("TOGGLE_SIDEBAR");
  },
  closeSideBar: function closeSideBar(_ref2, _ref3) {
    var commit = _ref2.commit;
    var withoutAnimation = _ref3.withoutAnimation;
    commit("CLOSE_SIDEBAR", withoutAnimation);
  },
  toggleDevice: function toggleDevice(_ref4, device) {
    var commit = _ref4.commit;
    commit("TOGGLE_DEVICE", device);
  },
  setSize: function setSize(_ref5, size) {
    var commit = _ref5.commit;
    commit("SET_SIZE", size);
  },
  sendMsg: function sendMsg(_ref6, data) {
    var commit = _ref6.commit;
    commit("SEND_MSG", data);
  },
  generateRoutes: function generateRoutes(_ref7, apps) {
    var dispatch = _ref7.dispatch,
      commit = _ref7.commit;
    // 格式
    var routes = apps.reduce(function (pre, app) {
      // 格式化入口
      if (app.debug && app.entry && app.entry.dev) {
        app.entry = app.entry.dev;
      } else if (app.entry && app.entry.manage) {
        app.entry = app.entry.manage;
      } else if ((0, _typeof2.default)(app.entry) == 'object') {
        app.entry = '';
      }
      // 格式化路由
      if (app.routes instanceof Array) {
        app.routes.forEach(function (item) {
          item.entry = app.entry;
          item.hidden = !!item.hidden;
          item.navbar = !!item.navbar;
          item.menu = !!item.menu;
          item.meta = {
            app: app.name,
            icon: item.icon,
            title: item.title,
            cache: !!item.cache
          };
          if (item.params) {
            item.path += +item.params;
          }
        });
        return pre.concat(app.routes);
      }
      return pre;
    }, []);
    // 排序
    routes.sort(function (prev, next) {
      return parseInt(prev.sort) - parseInt(next.sort);
    });
    // 构建菜单
    var navbar = _tree.default.listToTree(JSON.parse(JSON.stringify(routes)));
    // 构建路由
    return new Promise(function (resolve) {
      routes.forEach(function (item) {
        item.component = function () {
          return Promise.resolve().then(function () {
            return _interopRequireWildcard(require("@/layout"));
          });
        };
        if (item.view && (item.entry == '' || item.entry == '/')) {
          // 非layout布局：
          // let component = item.view
          // item.component = (resolve) => require([`@/views/${component}`], resolve)
          // 用layout布局：
          var child = JSON.parse(JSON.stringify(item));
          var component = child.view;
          child.component = function (resolve) {
            return require(["@/views".concat(component)], resolve);
          };
          item.children = [];
          item.children.push(child);
          item.name = item.name + "_layout";
        }
      });
      routes.push({
        path: "*",
        redirect: "/404",
        hidden: true
      });
      commit("SET_APPS", apps);
      commit("SET_ROUTES", routes);
      commit("SET_NAVBAR", navbar);
      resolve(routes);
    });
  }
};
var _default = {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};
exports.default = _default;